import { scrollTo } from '@brand-console/utilities'
import { Breadcrumbs, GrowthMarketingIcon } from '@cart/ui'
import React, { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { Recommendations } from '../../components/Recommendations'
import { GrowthMarketingForm } from '../../components/ServiceRequestForms/GrowthMarketingForm'
import { StickyHeader } from '../../components/StickyHeader'
import GrowthMarketingBannerPNG from '../../images/growth-marketing-banner.png'
import { ProductBanner } from './ProductBanner'
import { ServiceOfferingItem } from './ServiceOfferingItem'

export const GrowthMarketingRoute = (): ReactElement => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'GrowthMarketing',
      },
    })
  }, [])

  return (
    <main tw="relative">
      <section tw="px-6 py-4 sm:px-8">
        <Breadcrumbs items={[{ text: 'Home', href: '/' }, { text: 'Growth Marketing' }]} />
      </section>

      <StickyHeader
        overviewOnClick={() => scrollTo('#growth-marketing-overview', { offset: 90 })}
        offeringsOnClick={() => scrollTo('#growth-marketing-offerings', { offset: 90 })}
        requestOnClick={() => scrollTo('#growth-marketing-request', { offset: 90 })}
      >
        <GrowthMarketingIcon tw="mr-2 h-7 w-7" />
        <h4 tw="mb-0 mr-4 text-sm">Growth Marketing</h4>
      </StickyHeader>

      <section tw="m-auto w-full max-w-5xl px-6 py-8 sm:px-8 md:overflow-hidden">
        <ProductBanner
          tw="mb-24"
          title="Growth Marketing"
          description="Unlock revenue growth through our omnichannel marketing expertise"
          icon={GrowthMarketingIcon}
          bgImage={GrowthMarketingBannerPNG}
          buttonAction={() => scrollTo('#growth-marketing-request')}
        />
      </section>

      <section
        id="growth-marketing-overview"
        tw="m-auto w-full max-w-5xl px-6 pb-8 sm:px-8 md:overflow-hidden"
      >
        <h2 tw="mb-7 mt-0">Overview</h2>
        <p tw="text-lg">
          Cart Growth Marketing empowers retailers to deliver the customer-centric shopping
          experiences that fuel growth, advance digital transformation, boost revenue and drive
          brand loyalty. From ambitious startups to Fortune 100 companies, we&rsquo;re changing the
          way brands achieve omnichannel success with marketing strategies and services that
          simplify running your business.
        </p>
      </section>

      <section id="growth-marketing-offerings" tw="bg-white p-9">
        <div tw="mx-auto w-full max-w-5xl xl:px-8">
          <h2 tw="mb-6 text-2xl">Service Offerings</h2>

          <div tw="flex flex-wrap gap-9">
            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Search Engine Optimization</h3>
              <p tw="m-0">
                Informed by customer and competitive data, our team of SEO experts help you increase
                your page rank across relevant to search terms by leveraging and integrating
                technical, on-page, and off-page approaches.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Integrated Media Management</h3>
              <p tw="mb-0">
                Let us implement data-driven, performance and organic media strategies across
                channels including search and social, traditional media, and influencer and
                affiliate programs.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">eCRM and Lifecycle Management</h3>
              <p tw="mb-0">
                Unlock incremental revenue at a high conversion rate and ROAS with your first-party
                data. We analyze and segment your customer file to execute robust multichannel
                retargeting and loyalty programs that can extract greater LTV out of existing
                audiences.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Marketplace channel management</h3>
              <p tw="mb-0">
                Let our team manage your direct-to-consumer website as well as your third-party
                digital shopping channels such as eBay, Amazon, Walmart, Google Shopping and many
                more. Synchronize your data to your direct-to-consumer site and create a single
                source of truth to easily manage your expansion into third party marketplaces.
              </p>
            </ServiceOfferingItem>

            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Marketing Intelligence</h3>
              <p tw="mb-0">
                Our in-house data scientists and business intelligence experts collect, analyze,
                segment, and characterize customer and competitive data to optimize existing
                marketing strategies and identify new ones. We layer in third party data sets and
                use various statistical techniques to sharpen audience targeting across media,
                geographies, demographics, and psychographic dimensions.
              </p>
            </ServiceOfferingItem>
          </div>
        </div>
      </section>

      <section tw="m-auto w-full max-w-5xl px-6 py-8 sm:px-8">
        <h2 id="growth-marketing-request">Service Request</h2>
        <p tw="mb-8 text-base">Please fill out the below form to request the service.</p>
          <GrowthMarketingForm />
      </section>

      <section tw="[max-width: 100vw] m-auto w-full px-6 py-8 sm:([max-width: calc(100vw - 100px)] px-8) lg:max-w-5xl">
        <h2 tw="mb-6 pl-4 text-2xl lg:pl-0">Recommendations</h2>
        <Recommendations pageToOmit="/growth-marketing" />
      </section>
    </main>
  )
}
