import { Services } from '@brand-console/types'
import { scrollTo } from '@brand-console/utilities'
import { Breadcrumbs, MarketplaceServicesIcon, useBreakpoint } from '@cart/ui'
import React, { ReactElement, useEffect } from 'react'
import TagManager from 'react-gtm-module'

import { Recommendations } from '../../components/Recommendations'
import { MarketplaceServicesForm } from '../../components/ServiceRequestForms/MarketplaceServicesForm'
import { StickyHeader } from '../../components/StickyHeader'
import MarketplaceServicesBannerPNG from '../../images/marketplace-services-banner.png'
import { ProductBanner } from './ProductBanner'
import { ServiceOfferingItem } from './ServiceOfferingItem'

export const MarketplaceServicesRoute = (): ReactElement => {
  const isMedium = useBreakpoint('md')

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        route: 'MarketplaceServices',
      },
    })
  }, [])

  return (
    <main tw="relative">
      <section tw="px-6 py-4 sm:px-8">
        <Breadcrumbs items={[{ text: 'Home', href: '/' }, { text: 'Marketplace Services' }]} />
      </section>

      <StickyHeader
        baseTopPosition={isMedium ? 300 : 280}
        overviewOnClick={() => scrollTo('#marketplace-services-overview', { offset: 90 })}
        offeringsOnClick={() => scrollTo('#marketplace-services-offerings', { offset: 50 })}
        requestOnClick={() => scrollTo('#marketplace-services-request', { offset: 90 })}
      >
        <MarketplaceServicesIcon tw="mr-2 h-7 w-7" />
        <h4 tw="mb-0 mr-4 text-sm">Marketplace Services</h4>
      </StickyHeader>

      <section tw="m-auto w-full max-w-5xl px-6 py-8 sm:px-8 md:overflow-hidden">
        <ProductBanner
          tw="mb-24"
          title="Marketplace Services"
          description="Get product visibility and conversions in the world’s most popular marketplaces."
          icon={MarketplaceServicesIcon}
          bgImage={MarketplaceServicesBannerPNG}
          buttonAction={() => scrollTo('#marketplace-services-request', { offset: 90 })}
        />
      </section>

      <section
        id="marketplace-services-overview"
        tw="m-auto w-full max-w-5xl px-6 pb-8 sm:px-8 md:overflow-hidden"
      >
        <h2 tw="mb-7 mt-0">Overview</h2>
        <p tw="text-lg">
          Our multi-disciplinary experts hail from Google, Facebook, The Home Depot, Wayfair,
          Walmart, Amazon and some of the biggest ecommerce brands. We are business leaders,
          analysts, marketers, creatives, and technologists. No matter the size of your brand or the
          industry you&apos;re in, we&apos;ll work hand-in-hand to optimize and grow your online
          business.
        </p>
      </section>

      <section id="marketplace-services-offerings" tw="bg-white p-9">
        <div tw="mx-auto w-full max-w-5xl xl:px-8">
          <h2 tw="mb-6 text-2xl">Service Offerings</h2>
          <div tw="grid gap-8 sm:grid-cols-2">
            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Boost Marketplace Advertising</h3>
              <p tw="m-0">
                We audit your current advertising performance, explore strategic customer
                acquisition opportunities, automate reporting, improve data visibility and roll up
                our sleeves to collaborate with you on planning and review sessions with our team of
                experts.
              </p>
            </ServiceOfferingItem>
            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Optimize Marketplace Content</h3>
              <p tw="m-0">
                Our team will audit your current content performance, perform SEO and keyword
                research, create brand store and listing updates, execute A+ content and text
                overlays, and continually monitor these changes to optimize your performance for
                both desktop and mobile shopping experiences.
              </p>
            </ServiceOfferingItem>
            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">Streamline Marketplace Inventory Processes</h3>
              <p tw="m-0">
                Managing inventory can make or break your year. Our Cart Marketplaces team will help
                you with forecasting, sales trend data and insights, 1P vs. 3P catalog segmentation,
                inventory health monitoring (LTSF) as well as initiating and monitoring shipments to
                FBA.
              </p>
            </ServiceOfferingItem>
            <ServiceOfferingItem>
              <h3 tw="mb-2 text-xl">{Services.MARKETPLACE_MANAGEMENT}</h3>
              <p tw="m-0">
                Cart {Services.MARKETPLACE_MANAGEMENT} is a powerful suite of tools that allows
                ecommerce brands to list and update their products in the world&rsquo;s largest
                marketplaces from a single location. Easily build a centralized product catalog,
                bulk upload new listings to multiple channels and quickly resolve
                marketplace-specific issues in one place.
              </p>
            </ServiceOfferingItem>
          </div>
        </div>
      </section>

      <section tw="m-auto w-full max-w-5xl px-6 py-8 sm:px-8">
        <h2 id="marketplace-services-request">Service Request</h2>
        <p tw="mb-8 text-base">Please fill out the below form to request the service.</p>
          <MarketplaceServicesForm />
      </section>

      <section tw="[max-width: 100vw] m-auto w-full px-6 py-8 sm:([max-width: calc(100vw - 100px)] px-8) lg:max-w-5xl">
        <h2 tw="mb-6 pl-4 text-2xl lg:pl-0">Recommendations</h2>
        <Recommendations pageToOmit="/marketplace-services" />
      </section>
    </main>
  )
}
