import { logger, LoggerNames } from '@brand-console/utilities'
import { Alert, Button, Card, CardContent, Link } from '@cart/ui'
import CardActions from '@mui/material/CardActions'
import React, { FC, ReactNode, useState } from 'react'
import tw from 'twin.macro'

import { ServiceRequestFormModal } from '../Modals/ServiceRequestFormModal'
import { FormProvider } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useCartAuth, useCurrentContext } from '@cartdotcom/auth'
import { TrialFormSkeleton } from '../TrialForm/components/SkeletonForm'
import { AccountConfirmForm, AccountConfirmFormProvider, useAccountConfirmForm } from '../AccountConfirmForm'

type BaseServiceRequestFormProps = {
  children?: ReactNode
  onSubmit: (data) => Promise<boolean>
  formName: string
  logName: LoggerNames
  methods: ReturnType<typeof useForm>
}
export const BaseServiceRequestForm: FC<BaseServiceRequestFormProps> = ({
  children,
  formName,
  onSubmit,
  logName,
  methods,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [dialogShowing, setDialogShowing] = useState(false)
  const [errorMessage, setErrorMessage] = useState<ReactNode>(null)

  const { currentBusiness, isLoaded, currentOrganization } = useCurrentContext()
  const log = logger.setLogger(logName)

  const { firstName, lastName, phone } = useCartAuth()
  const formData = useAccountConfirmForm({ methods, log })
  const { handleSubmit } = methods
  const { submitForm: submitAccountForm } = formData

  const handleSubmitForm = async (data) => {
    const dataWithDefaultValues = {
      firstName: data.firstName || firstName,
      lastName: data.lastName || lastName,
      phone: data.phone || phone,
      businessName: data.businessName || currentBusiness?.name,
      orgName: data.businessName || currentOrganization?.name,
      ...data,
    }

    setLoading(true)
    setErrorMessage(undefined)

    try {
      if (
        !(await submitAccountForm({
          data: dataWithDefaultValues,
          onError: (error, friendlyErrorMessage, scope) => {
            log.error(
              'Error submitting account confirmation form',
              {
                friendlyErrorMessage,
                scope,
              },
              error,
            )
            setErrorMessage(friendlyErrorMessage)
            setLoading(false)
          },
        }))
      ) {
        return
      }

      if (await onSubmit(dataWithDefaultValues)) {
        setDialogShowing(true)
      }
    } catch (error) {
      log.error(`${formName} subscription error`, error)
      setErrorMessage(
        <>
          There was an error while submitting your request. Please try again or, if the error
          persists, <Link href="https://www.cart.com/contact">click here</Link> to contact us.
        </>,
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <section tw="mb-9 flex justify-center">
        <AccountConfirmFormProvider value={formData} methods={methods}>
          <form tw="w-full max-w-[692px]" onSubmit={handleSubmit(handleSubmitForm)} noValidate>
            <Card>
              <CardContent tw="px-6">
                <small tw="mb-4 block text-right text-primary-700">
                  * Indicate required fields
                </small>
                <ol tw="ml-0 pl-4 [list-style-type:decimal]">{children}</ol>
                {isLoaded ? (
                  <AccountConfirmForm headerProps={{ style: tw`px-0 pt-0` }} />
                ) : (
                  <TrialFormSkeleton hideButton />
                )}
              </CardContent>
              <CardActions tw="mb-6 flex-col">
                <>
                  {errorMessage && (
                    <Alert severity="error" tw="mb-6" onClose={() => setErrorMessage(undefined)}>
                      {errorMessage}
                    </Alert>
                  )}
                  <Button type="submit" variant="contained" loading={loading}>
                    Submit
                  </Button>
                </>
              </CardActions>
            </Card>
          </form>
        </AccountConfirmFormProvider>
      </section>
      <ServiceRequestFormModal isShowing={dialogShowing} setIsShowing={setDialogShowing} />
    </>
  )
}
