/* eslint-disable react/jsx-props-no-spreading */
import { Button } from '@cart/ui'
import React from 'react'

export const ProductBanner = ({
  title,
  description,
  icon: Icon,
  bgImage,
  buttonAction,
  buttonText = 'Request Service',
  isRequesting = false,
  ...props
}) => {
  return (
    <>
      <div tw="bg-white p-7 rounded-[6px] md:hidden" {...props}>
        <div tw="mb-4 flex items-center">
          <Icon tw="mr-2.5 w-10" />
          <h1 tw="mb-0 text-[calc(1.4107rem + 1.9284vw)]">{title}</h1>
        </div>
        <p tw="mb-7">{description}</p>
        <Button
          tw="w-full justify-center"
          onClick={buttonAction}
          loading={isRequesting}
          variant="contained"
        >
          {buttonText}
        </Button>
      </div>
      <div
        tw="relative hidden overflow-y-hidden bg-white pt-9 pl-24 h-[245px] rounded-[6px] md:(flex items-start)"
        {...props}
      >
        <Icon tw="mr-9 w-24" />
        <div tw="[z-index: 1] flex flex-col">
          <h1>{title}</h1>
          <p tw="rounded-sm bg-white px-2 text-lg sm:w-1/2">{description}</p>
          <Button
            tw="justify-center self-start"
            onClick={buttonAction}
            loading={isRequesting}
            variant="contained"
          >
            {buttonText}
          </Button>
        </div>
        <img
          src={bgImage}
          alt=""
          tw="absolute top-0 right-0 object-scale-down object-right-top max-h-[300px]"
        />
      </div>
    </>
  )
}
